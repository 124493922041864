// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

RegExp.escape = function (str) {
	return (str + '').replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, '\\$1');
};

KLMR.ww = window.innerWidth;
KLMR.wh = window.innerHeight;
KLMR.mobileWindowWidth = 768;
KLMR.tabletWindowWidth = 1039;

$(document).ready(function () {
	//e-mail protection init
	$('body').emailSpamProtection('email');

	/*
    Global search autocomplete
  */
	var lang = $('html').attr('lang');
	var allowedChars = new RegExp(/^[a-zA-Z\s]+$/);

	function charsAllowed(value) {
		return allowedChars.test(value);
	}
	var globalSearchForms = document.querySelectorAll('.globalSearch');
	globalSearchForms.forEach(function (theForm) {
		autocomplete({
			input: theForm.querySelector('.search-query'),
			minLength: 2,
			onSelect: function (item, inputfield) {
				inputfield.value = item.label;
				theForm.submit();
			},
			fetch: function (text, callback) {
				$.ajax({
					url: '/' + lang + '/api/autocomplete/search?q=' + $.trim(text),
					dataType: 'json',
					success: function (data) {
						callback(
							$.map(data.Data, function (item) {
								return {
									label: item,
								};
							})
						);
					},
				});
			},
			render: function (item, value) {
				var itemElement = document.createElement('div');
				if (charsAllowed(value)) {
					var regex = new RegExp(value, 'gi');
					var inner = item.label.replace(regex, function (match) {
						return '<strong>' + match + '</strong>';
					});
					itemElement.innerHTML = inner;
				} else {
					itemElement.textContent = item.label;
				}
				return itemElement;
			},
			emptyMsg: '',
			customize: function (input, inputRect, container, maxHeight) {
				container.classList.add('global-autocomplete-menu');
			},
		});
	});

  $('div.video').on('triggerStopVideo', function () {
    var iframeElement = $(this).find('iframe');
    if (iframeElement.length > 0) {
      // send pause command only to visible items
      if ($(iframeElement).parents('[class*="hide"]').length == 0) {
        // don't use a stopVideo. pauseVideo func - video header remains in player
        iframeElement[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
      }
    }
    return false;
  });

	$('.footer__links__button').each(function (el) {
		$(this).on('focus', function () {
			handlePopupPosition(this);
		});
		$(this).on('mouseenter', function () {
			handlePopupPosition(this);
		});

		$(this).on('blur', function () {
			$(this).find('.popup-content').css('display', 'none');
		});
		$(this).on('mouseleave', function () {
			$(this).find('.popup-content').css('display', 'none');
		});
	});
});

/**
 * Function to handle the position of the popup
 * @param {HTMLElement | null} button
 */
function handlePopupPosition(button) {
	if (!button) return;

	const popup = $(button).find('.popup-content');
	popup.css('display', 'flex');

	adjustPopupPosition(popup[0]);
}

/**
 * Function to handle the position of the popup
 * @param {HTMLElement} popup
 */
function adjustPopupPosition(popup) {
	const rect = popup.getBoundingClientRect();

	if (rect.bottom > window.innerHeight) {
		popup.style.bottom = 'auto';
		popup.style.top = '100%';
	}

	if (rect.top < 0) {
		popup.style.top = 'auto';
		popup.style.bottom = '100%';
	}

	if (rect.right > window.innerWidth) {
		popup.style.right = '0';
		popup.style.left = 'auto';
	}

	if (rect.left < 0) {
		popup.style.left = '0';
		popup.style.right = 'auto';
	}
}

KLMR.setCookie = function (name, value, days) {
	let expires = '';
	if (days) {
		let date = new Date();
		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
		expires = '; expires=' + date.toGMTString();
	}
	document.cookie = name + '=' + value + expires + '; path=/';
};

KLMR.getCookie = function (name) {
	let nameEQ = name + '=';
	let ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1, c.length);
		}
		if (c.indexOf(nameEQ) == 0) {
			return c.substring(nameEQ.length, c.length);
		}
	}
	return null;
};
KLMR.swapChars = function (text, key) {
  // applies XOR function on a text in order to obfuscate/clarify it
  var result = '';
  key = key || '5125';
  for (let i = 0; i < text.length; ++i) {
    result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
  }

  return result;
};
