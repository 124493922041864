window.ParsleyValidator.addValidator('education', function () {
    if ($('#education').val() == '') {
        return true;
    }
    if ($('#graduatedYear').val() == '' || $('#graduatedDegree').val() == '') {
        return false;
    }
    return true;
}, 32);

window.ParsleyValidator.addValidator('training', function () {
    if ($('#training').val() == '') {
        return true;
    }
    if ($('#trainingStart').val() == '' || $('#trainingEnd').val() == '') {
        return false;
    }
    return true;
}, 32);

window.ParsleyValidator.addValidator('job', function () {
    if ($('#company').val() == '') {
        return true;
    }
    if ($('#position').val() == '' || $('#workStart').val() == '') {
        return false;
    }
    return true;
}, 32);

window.ParsleyValidator.addValidator('language', function () {
    if ($('#language').val() == '') {
        return true;
    }
    if ($('#langLevel').val() == '') {
        return false;
    }
    return true;
}, 32);
