function pushSubmitEvent(el, eventName, reason) {
    var eventNameString = eventName != undefined ? eventName : $(el).find('.Form__Title').text();
    var gtmArgs = {
        'event': 'FormSubmitSuccess',
        'formType': eventNameString,
        'formId': $(el).attr('id'),
        'formButton': $.trim($(el).find('.FormSubmitButton').text())
    };
    if (reason) {
        gtmArgs.formReasontoContact = reason;
    };

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(gtmArgs);
}

var _globalSelectedReasonValue = null;

if (typeof $$epiforms !== 'undefined') {
    $$epiforms(document).ready(function myfunction() {        
        $$epiforms(".EPiServerForms").on("formsStartSubmitting", function (event, param1, param2) {
            // getting the reason of contact value
            var contactUsForm = $('#contact-us-form');
            if (contactUsForm.length != 0 && $(event.target).closest('#contact-us-form')[0] == contactUsForm[0]) {

                var reasonField = contactUsForm.find('._GTM_Reason_of_contact');
                if (reasonField.length != 0) {
                    var selTextValue = reasonField.find(":selected").text();
                    _globalSelectedReasonValue = selTextValue;
                }
            }
        });

        $$epiforms(".EPiServerForms").on("formsSubmitted", function (event, param1, param2) {

            // contact us form
            var contactUsForm = $('#contact-us-form');
            if (contactUsForm.length != 0 && $(event.target).closest('#contact-us-form')[0] == contactUsForm[0] && event.isSuccess) {
                pushSubmitEvent(event.target, 'Contact Us', _globalSelectedReasonValue);
                return;
            }
            // other forms
            var field = $(event.target).parent().find('.GtmDataLayerField');
            if (field.length && event.isSuccess) {
                var text = field[0].value;
                pushSubmitEvent(event.target, text);
                return;
            }

            //TODO: remove this when the forms are set up on production after deploy
            var formHandled = false;
            // campaign form - 99 reasons
            var subscribeForm = $('#reason-form');
            if (subscribeForm.length != 0 && $(event.target).parent()[0] == subscribeForm[0] && event.isSuccess) {
                pushSubmitEvent(event.target, 'Subscribe');
                formHandled = true;
            }
            // campaign form (green-chair)
            var subscribeForm = $('#campaign-form');
            if (subscribeForm.length != 0 && $(event.target).parent()[0] == subscribeForm[0] && event.isSuccess) {
                pushSubmitEvent(event.target, 'Subscribe');
                formHandled = true;
            }
            // download form
            var downloadForm = $('#downloadForm');
            if (downloadForm.length != 0 && $(event.target).parent()[0] == downloadForm[0] && event.isSuccess) {
                pushSubmitEvent(event.target, 'Download Material');
                formHandled = true;
            }
            // webinar form
            var webinarForm = $('#webinarform');
            if (webinarForm.length != 0 && $(event.target).parent()[0] == webinarForm[0] && event.isSuccess) {
                pushSubmitEvent(event.target, 'Webinar Signup');
                formHandled = true;
            }            
            // subscriptions form - publications order
            var subsForm = $('#subscriptionsForm');
            if (subsForm.length != 0 && $(event.target).parent()[0] == subsForm[0] && event.isSuccess) {
                pushSubmitEvent(event.target, 'Subscribe');
                formHandled = true;
            }
            // campaign contact expert form
            var campaignForm = $('#campaignForm');
            if (campaignForm.length != 0 && $(event.target).parents('#campaignForm').length && event.isSuccess) {
                pushSubmitEvent(event.target, 'Want more information');
                formHandled = true;
            }
            
            if (!formHandled) {
                if (event.isSuccess) {
                    pushSubmitEvent(event.target);
                }
            }
        });
    });
}