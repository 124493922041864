(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jQuery"));
	else if(typeof define === 'function' && define.amd)
		define(["jQuery"], factory);
	else if(typeof exports === 'object')
		exports["scripts-facelift"] = factory(require("jQuery"));
	else
		root["KLMR"] = root["KLMR"] || {}, root["KLMR"]["scripts-facelift"] = factory(root["jQuery"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__4786__) => {
return 