$(function () {
	/**
	 * Registering menu event handlers
	 * @param {Number} level Level of the menu
	 */
	function registerHandlers(level) {
		$('.mega-menu-level-' + level).hide();

		let links = $('[data-menu-level-' + level + ']');
		// #endregion

		$('[data-menu-target]').on('click', function () {
			const target = $(this);
			$('.mega-menu-level-' + level).hide();
			$(target.data('menu-level-' + level)).fadeIn(100);
		});

		// #region [Enter] -> focus first link in the submenu || go to the page
		links.on('keydown', function (event) {
			if (event.keyCode == 13 || event.key == 'Enter') {
				let link = $(this);
				let firstTarget = link.data('menu-level-2') ?? link.data('menu-level-3');
				if (firstTarget != undefined) {
					event.preventDefault();
					event.stopPropagation();
					$(firstTarget + ' a:first').focus();
				}
			}
			if (event.keyCode == 27 || event.key == 'Escape') {
				closeAllMenuLinks();
			}
		});
		// #endregion

		// #region [Esc] -> focus the closest link targeting the active submenu
		$('.mega-menu a.menu-level-' + level + '-link, .mega-menu h3.menu-level-' + level + '-heading > a').on(
			'keydown',
			function (event) {
				if (event.keyCode == 27 || event.key == 'Escape') {
					let link = $(this);
					let parentId = link.closest('.mega-menu-level-' + level).attr('id');
					if (parentId != undefined) {
						event.preventDefault();
						event.stopPropagation();
						$(
							'.mega-menu-level-' + (level - 1) + ' a[data-menu-level-' + level + "='#" + parentId + "']"
						).focus();
					}
				}
			}
		);
		// #endregion
	}

	// Registering menu event handlers
	registerHandlers(3);

	// #region Desktop menu controls
	function closeAllMenuLinks() {
		$('.main-nav-item').removeClass('open');

		$('[data-menu-level-2]').each(function () {
			$(this).attr('aria-expanded', 'false');
			const targetMenu = $(this).data('menu-level-2');
			$(targetMenu).css('display', 'none');
		});
	}

	function handleMenuLink(menuLink) {
		if ($(menuLink).hasClass('open')) {
			closeAllMenuLinks();
			return;
		}
		// Close all menu links
		closeAllMenuLinks();

		// Open the clicked menu link
		$(menuLink).addClass('open');
		$(menuLink).find('button').attr('aria-expanded', 'true');
		const target = $(menuLink).find('[data-menu-level-2]');
		target.toggleClass('open');

		const targetMenu = target.data('menu-level-2');
		$(targetMenu).css('display', 'block');
	}

	$('.main-nav-item').on('click', function () {
		handleMenuLink(this);
	});

	$('.main-nav-item').on('keydown', function (event) {
		if (event.keyCode == 27 || event.key == 'Escape') {
			closeAllMenuLinks();
		}
	});

	// #endregion

	function closeMobileMenu() {
		$('.header__mobile-nav__menu').removeClass('open');
		$('.header__mobile-nav__menu__mega-menu__container').each(function () {
			$(this).removeClass('show');
		});
	}

	// #region Mobile menu controls
	$('.header__mobile-nav__button').on('click', function () {
		$('.header__mobile-nav__menu').toggleClass('open');
		$('#mobile-menu-1').addClass('show');
	});

	// Mobile menu
	$('.menu-button-submenu').on('click', function () {
		const target = $(this).data('target');

		const nextLevel = $('#mobile-menu-' + target);

		if (!nextLevel) {
			return;
		}

		nextLevel.toggleClass('show');
		$(this).closest('div').toggleClass('show');
	});
	// #endregion

	// Close all menu links when clicking outside
	$(window).on('click', function (event) {
		if (!$(event.target).closest('.header').length) {
			closeAllMenuLinks();
			closeMobileMenu();
		}
	});
});
